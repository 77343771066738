<template>
	<b-overlay :show="loading" rounded="sm">
		<!-- 1 -->
		<b-card no-body class="mb-4">
			<!-- Search Control -->
			<b-card-body class="p-3 d-flex justify-content-between">
				<b-form-group class="mb-2 w-lg-25">
					<b-input-group>
						<b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>

						<b-input-group-append>
							<b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
				<!-- <div class="d-flex align-items-center">
                        <b-button variant="primary" class="mr-2" v-b-modal.modal-center>Add</b-button>
                        <b-button variant="secondary">Print</b-button>
                    </div> -->
			</b-card-body>
			<!-- End Section -->

			<!-- Data Table -->
			<b-table
				style="min-height: 250px;"
				show-empty
				responsive
				small
				class="mb-0"
				head-variant="light"
				:items="customers"
				:filter="filter"
				:fields="fields"
				hover
			>
				<template #cell(id)="data"> #{{ data.item.id }} </template>
				<template #cell(origin)> Old Software </template>
				<template #cell(isActive)="data">
					<b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
					<b-badge variant="danger" v-else>Inactive</b-badge>
				</template>
				<template #cell(action)>
					<b-button variant="outline-danger" pill class="d-flex align-items-center mr-0">
						<feather type="trash" class="feather-sm"></feather>
					</b-button>
				</template>
			</b-table>
			<!-- End Section -->

			<!-- Pagination -->
			<div class="p-3 d-md-flex align-items-center">
				<b-form-group
					label="Per page"
					label-for="per-page-select"
					label-cols-sm="6"
					label-cols-md="4"
					label-cols-lg="9"
					label-size="sm"
					label-class="fw-medium"
					class="mb-0"
				>
					<b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm" @input="getData"></b-form-select>
				</b-form-group>
				<div class="ml-auto mt-3 mt-md-0">
					<b-pagination @input="getData" v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" class="my-0"></b-pagination>
				</div>
			</div>
			<!-- End Section -->
		</b-card>

		<b-modal id="modal-center" hide-footer centered title="Add a new comment">
			<b-form>
				<b-form-group label="First Name" label-class="fw-medium mb-1" label-for="firstName">
					<b-form-input id="firstName" type="text" placeholder="Your name here"></b-form-input>
				</b-form-group>

				<b-form-group label="Last Name" label-class="fw-medium mb-1" label-for="lastName">
					<b-form-input id="lastName" type="text" placeholder="Your last name here"></b-form-input>
				</b-form-group>

				<b-form-group label="Address" label-class="fw-medium mb-1" label-for="address">
					<b-form-input id="address" type="text" placeholder="Your address here"></b-form-input>
				</b-form-group>

				<b-form-group label="Type" label-class="fw-medium mb-1" label-for="type">
					<b-form-select :options="options" id="type" type="text" placeholder="Please select an option"></b-form-select>
				</b-form-group>

				<b-form-group label="Global ID" label-class="fw-medium mb-1" label-for="globalId">
					<b-form-input id="globalId" type="number" placeholder="0000"></b-form-input>
				</b-form-group>
				<b-button block variant="primary">Save Customer</b-button>
			</b-form>
		</b-modal>
	</b-overlay>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "Customers",

	data: () => ({
		page: {
			title: "SeachTable",
		},
		filter: null,
		fields: [
			{
				key: "id",
				sortable: true,
			},
			{
				key: "lastName",
				label: "Last Name",
				sortable: true,
			},
			{
				key: "firstName",
				label: "First Name",
				sortable: true,
			},
			{
				key: "address1",
				label: "Adreess",
				sortable: true,
			},
			{
				key: "reasonTypeDescription",
				label: "Type",
				sortable: true,
			},
			{
				key: "isActive",
				label: "Status",
				sortable: true,
			},
			{
				key: "origin",
				label: "Origin",
				sortable: true,
			},
		],

		totalRows: 1,
		currentPage: 1,
		perPage: 10,
		pageOptions: [5, 10, 20, 40, { value: 100, text: "Show a lot" }],

		options: [
			{ value: null, text: "Please select an option" },
			{ value: "person", text: "Person" },
		],

		loading: false,
	}),
	components: {},
	methods: {
		async getData() {
			this.loading = true;
			await this.$store.dispatch("customers/get_allToBuilders", { skip: this.currentPage, take: this.perPage });

			this.totalRows = this.totalCustomers;
			this.loading = false;
		},
	},
	computed: {
		...mapGetters({
			customers: "customers/getCustomers",
			totalCustomers: "customers/getTotalCustomers",
		}),
	},
	mounted() {},
	created() {
		this.getData();
	},
};
</script>
